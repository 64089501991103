import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TileMenu from "../components/TileMenu"
import styled from "styled-components"
import HeroImage from "../components/HeroImage"
import NextEvents from "../components/NextEvents"
import PodcastLastEpisodes from "../components/PodcastLastEpisodes"

const StyledIndex = styled.div`
  background-color: white;
  /* margin-top: -7rem !important; */
  /* padding: 1em;
  box-shadow: 0 0px 30px -10px rgba(0, 0, 0, 0.3);
  animation: top ease-in-out 1s forwards; */

  /* @keyframes top {
    0% {
      transform: translateY(0rem);
    }
    100% {
      transform: translateY(-7rem);
    }
  } */

  .videoContainer {
    margin-block: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .video {
    flex: 1;

    video {
      width: 100%;
      height: 50vh;
    }
  }

  .text {
    flex: 1;
    text-align: center;

    p {
      font-size: 1.5rem;
    }
  }

  .experts {
    width: 100%;
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
  }

  .expert {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;

    @media (max-width: 768px) {
      justify-content: space-between;
    }
  }

  .expert_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .expert a {
    font-weight: bold;
    color: #f07e14;
  }

  .expert a h2 {
    margin-bottom: 0;
  }

  .expert a:hover {
    color: #c36810;
  }

  .email {
    color: black;
    font-weight: normal;
    font-size: 1rem;
  }

  .expert a:hover .email {
    text-decoration: underline;
  }

  .expert_photo {
    width: 160px;
    height: 230px;
    object-fit: cover;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HeroImage />
    <StyledIndex className="container mt-5">
      <h2
        className="text-center text-uppercase"
        style={{ marginBottom: "2rem" }}
      >
        <strong>
          40 Jahre Erfahrung in Spritzgießtechnik – Wir sind die Experten
        </strong>
      </h2>

      <div className="experts">
        <div className="expert">
          <img src="/helmut.jpg" className="expert_photo" />
          <a href="mailto:h.kraft@spritzgiessfuehrerschein.de">
            <div className="expert_info">
              <h2>Helmut Kraft</h2>
              <p className="email">h.kraft@spritzgiessfuehrerschein.de</p>
            </div>
          </a>
        </div>
        <div className="expert">
          <img src="/heiko.jpg" className="expert_photo" />
          <a href="mailto:contact@koeferl-consulting.de">
            <div className="expert_info">
              <h2>Heiko Köferl</h2>
              <p className="email">contact@koeferl-consulting.de</p>
            </div>
          </a>
        </div>
        <div style={{ width: "100%", marginBlock: "2rem" }}>
          <h3>Erfahren Sie das Wichtigste in diesem Erklärfilm:</h3>
          <video
            src="/loft.mp4"
            controls
            poster="/loft.webp"
            style={{ width: "100%" }}
          ></video>
        </div>
      </div>

      {/* <div className="videoContainer">
        <div className="video">
          <video src="/simba.mp4" autoPlay muted controls loop />
        </div>
        <div className="text">
          <h2>Höchster Alarm!</h2>
          <p>
            Der Fachkräftemangel ist abwendbar durch Ausbildung und Schulungen
            bei uns!
          </p>
        </div>
      </div> */}
      <TileMenu />
      <PodcastLastEpisodes />
      {/* <NextEvents /> */}
    </StyledIndex>
  </Layout>
)

export default IndexPage
